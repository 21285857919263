module services {
    export module customs {
        export class goodsDeclarationService implements interfaces.customs.IGoodsDeclarationService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            generateGoodsDec(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/GenerateGoodsDeclarationEntries", {
                    consignmentId: "@consignmentId",
                    functionClassificationValueId: "@functionClassificationValueId",
                    versionChangeReason: "@versionChangeReason",
                    CreateClearingInstructionOnly: "@CreateClearingInstructionOnly",
                    GoodsDeclarationType : '@GoodsDeclarationType'
                });
            }

            getGoodsDeclarations(): ng.resource.IResourceClass<interfaces.customs.IGoodsDeclarationDisplay> {
                return this.$resource<interfaces.customs.IGoodsDeclarationDisplay>(this.ENV.DSP_URL + "GoodsDeclaration/GetGoodsDeclarations", {
                    consignmentId: "@consignmentId",
                    version: "@version",
                    getAll: "@getAll",
                    isClearingInstruction: "@isClearingInstruction",
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            delete(gsdId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/Delete", {
                    gsdId: gsdId
                });
            }

            deleteInvoice(invoiceId: number, goodsDecId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler>
            {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/DeleteInvoice", {
                    invoiceId: invoiceId,
                    goodsDecId: goodsDecId
                });
            }

            getGoodsDeclaration(): ng.resource.IResourceClass<interfaces.customs.IGoodsDeclaration> {
                return this.$resource<interfaces.customs.IGoodsDeclaration>(this.ENV.DSP_URL + "GoodsDeclaration/GetGoodsDeclaration", {
                    goodsDecId: "@goodsDecId",
                    version: "@version"
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getEDIFactMessageDetail(): ng.resource.IResourceClass<interfaces.integration.IEDIFactMessage> {
                return this.$resource<interfaces.integration.IEDIFactMessage>(this.ENV.DSP_URL + "GoodsDeclaration/GetEDIFactMessageDetail", {
                    mesId: "@mesId"                    
                }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            saveAndValidateEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/SaveAndValidateEDI");
            }

            validateAndSendEDI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ValidateandSendEDI");
            }

            requestEDIResponseResend(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RequestEDIResponseResend", {
                    ownerEntityId: "@ownerEntityId",
                    goodsDecId: "@goodsDecId"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/Save");
            }

            recalculateDuties(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RecalculateDuties");
            }

            changeCPCCode(goodsDeclarationId: number, procedureCategoryId: number, customsProcedureId?: number, prevCustomsProcedureId?: number, procedureMeasureCode?: string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ChangeCPCCode", {
                    goodsDeclarationId: goodsDeclarationId,
                    procedureCategoryId: procedureCategoryId,
                    customsProcedureId: customsProcedureId,
                    prevCustomsProcedureId: prevCustomsProcedureId,
                    procedureMeasureCode: procedureMeasureCode,
                });
            }

            submitDefaultCaseDocuments(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/SubmitDefaultCaseDocuments", {
                    goodsDeclarationId: goodsDeclarationId
                });
            }

            createManualDeclaration(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/CreateManualDeclaration");
            }            

            generateFromInvoice(goodsDecId:number, version:number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/GenerateFromInvoice", {
                    goodsDecId: goodsDecId,
                    version: version
                });
            }

            changeCustomsEDIStatus(goodsDeclarationId: number, ediStatusId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/ChangeCustomsEDIStatus", {
                    goodsDeclarationId: goodsDeclarationId,
                    ediStatusId: ediStatusId
                });
            }

            recalculateTotals(goodsDeclarationId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclaration/RecalculateTotals", {
                    goodsDeclarationId: goodsDeclarationId                    
                });
            }            

        }
    }
    angular.module("app").service("goodsDeclarationService", services.customs.goodsDeclarationService);
}